import { useFrame, useThree } from "@react-three/fiber";
import { useEventListener } from "app/event-listener";
import { PI, clamp, rad } from "app/math";
import { useDispatch, useSelector } from "app/store";
import {
  DbEntry,
  setDefaultView,
  selectModel,
  setScreen,
  setTier,
  setVariant,
  setCameraDestination,
  setSectionView,
  setAnimationRunning,
} from "features/ui/slice";
import { FC, useEffect, useRef, useState } from "react";
import { Vector3 } from "three";

const ratio = 0.8;
interface Props {
  upAndDown?: boolean;
  target?: [number, number, number];
  animate?: boolean;
  defaultHeight?: number;
  defaultTarget?: number;
}

const f = (x: number) => x;

export const destinations: {
  [key: string]: {
    radius: number;
    angle: number;
    height?: number;
    targetHeight?: number;
    model?: DbEntry;
  };
} = {
  // house 1 roof
  "1 1 1": {
    radius: 1.6,
    angle: 0.8319984544256981,
    height: 1,
    model: "крыша",
  },
  // house 1 walls
  "3 3 1": {
    radius: 2.41,
    angle: -3.16,
    targetHeight: -0.4,
    height: -0.4,
    model: "стены",
  },
  // house 1 floors
  "4 4 1": {
    radius: 2,
    angle: -3.160907484359741,
    model: "полы",
  },
  // house 1 interfloor
  "2 2 1": {
    radius: 2.4038998169622765,
    angle: 0.004629861077885306,
    height: 0,
    model: "межэтажное",
  },
  // house 1 foundation
  "6 6 1": {
    radius: 2.4,
    angle: -2,
    height: -0.85,
    model: "фундамент",
  },
  // house 2 roof
  "1 1 2": {
    radius: 1.6,
    angle: 0.8319984544256981,
    height: 1,
    model: "крыша",
  },
  // house 2 walls
  "3 3 2": {
    radius: 2.86,
    angle: -3.1877190577376986,
    targetHeight: -0.4,
    height: -0.4,
    model: "стены",
  },
  // house 2 floors
  "4 4 2": {
    radius: 2,
    angle: -3.160907484359741,
    model: "полы",
  },
  // house 2 interfloor
  "2 2 2": {
    radius: 2.4038998169622765,
    angle: 0.004629861077885306,
    height: 0,
    model: "межэтажное",
  },
  // house 2 foundation
  "6 6 2": {
    radius: 2.3,
    angle: -1.1537324926804806,
    model: "фундамент",
  },
  // house 1 base
  "5 5 1": {
    radius: 1.8,
    angle: -2,
    height: -0.9,
    model: "цоколь",
  },
  // house 2 base
  "5 5 2": {
    radius: 2,
    angle: -0.7990442490904092,
    model: "цоколь",
  },
  // default model view
  default: {
    radius: 2.4,
    angle: -PI / 2 - PI / 3,
  },
  // house 1 default
  "1 default": {
    radius: 3.1899999999999986,
    angle: -1.1780235837034185,
  },
  // house 2 default
  "2 default": {
    radius: 3.309999382184656,
    angle: -1.972446299574046,
  },
};

export const CameraControls: FC<Props> = ({
  upAndDown,
  target: _target,
  animate,
  defaultHeight = -0.7,
  defaultTarget = -0.9,
}) => {
  const three = useThree();
  const pointerDown = useRef<boolean>(false);
  const targetHeight = _target?.[1] ?? defaultTarget;
  const target = useRef(new Vector3(...(_target || [0, defaultTarget, 0])));
  const angle = useRef<number>(-PI / 2 + PI / 4);
  const height = useRef<number>(defaultHeight);
  const radius = useRef<number>(2.4);

  // click detector
  const clicking = useRef<boolean>(false);

  // handle canvas clicks
  const dispatch = useDispatch();
  const selection = useSelector(s => s.ui.selection);
  const hc = useSelector(s => s.ui.hoverCode);
  const chc = useSelector(s => s.ui.clickedHoverCode);
  const house = useSelector(s => s.ui.house);
  const screen = useSelector(s => s.ui.screen);
  const destination = useSelector(s => s.ui.cameraDestination);
  const sectionView = useSelector(s => s.ui.sectionView);

  const goToHouseDetail = (destination: string) => {
    const model = destinations[destination]?.model;
    if (model) {
      dispatch(selectModel(model));
      dispatch(setScreen("house-detail"));
      if (selection.options[model] !== undefined) {
        dispatch(setVariant(selection.options[model][0]));
        dispatch(setTier(selection.options[model][1]));
      } else {
        dispatch(setVariant(0));
        dispatch(setTier(0));
      }
    }
  };

  // set default camera position on button click
  const defaultView = useSelector(s => s.ui.defaultView);
  console.log("default view", defaultView);
  useEffect(() => {
    if (defaultView) {
      if (screen === "house") {
        dispatch(setCameraDestination(`${house} default`));
      } else dispatch(setCameraDestination("default"));
    }
  }, [defaultView]);

  const onClick = (e: MouseEvent) => {
    if (
      (e.target as HTMLElement)?.tagName === "CANVAS" &&
      chc !== -1 &&
      animate
    ) {
      // goToHouseDetail();
      console.log("click", hc, chc, house);
      const key = `${hc} ${chc} ${house}`;
      if (destinations[key]) {
        setTimeout(() => goToHouseDetail(key), 1500);
        dispatch(setCameraDestination(key));
      }
    }
  };

  useEventListener("wheel", (e: WheelEvent) => {
    if (
      e.target &&
      e.target instanceof HTMLElement &&
      e.target.tagName !== "CANVAS"
    )
      return;
    // temporarily(!) disable camera controls when the user is in the configurator
    // if (screen === "configurator") return;
    const delta = e.deltaY;
    // disable animation when the user starts to drag manually
    dispatch(setCameraDestination(""));
    // signal leaving the default view
    dispatch(setDefaultView(false));
    console.log("camera", radius.current, angle.current);
    radius.current = clamp(radius.current + delta * 0.001, 1.8, 4);
  });
  useEventListener("pointerdown", () => {
    pointerDown.current = true;
    clicking.current = true;
  });
  useEventListener("pointermove", e => {
    clicking.current = false;
    // temporarily(!) disable camera controls when the user is in the configurator
    // if (screen === "configurator") return;
    if (pointerDown.current) {
      console.log("camera", {
        radius: radius.current,
        angle: angle.current,
        height: height.current,
      });
      if (defaultView) {
        dispatch(setDefaultView(false));
      }
      if (sectionView) {
        dispatch(setSectionView(false));
      }
      if (destination) {
        // disable animation when the user starts to drag manually
        dispatch(setCameraDestination(""));
      }
      if (upAndDown) {
        const y = e.movementY || 0;
        target.current.y = clamp(target.current.y + y * 0.005, -1.8, 0.4);
      }
      const x = e.movementX;

      angle.current = (angle.current + rad(x * 0.3)) % (PI * 2);
    }
  });
  useEventListener("pointerup", (event: MouseEvent) => {
    pointerDown.current = false;
    if (clicking.current) {
      onClick(event);
    }
  });
  const MIN_DELTA = 0.002;
  const animationRunning = useSelector(s => s.ui.animationRunning);
  useFrame((three, delta) => {
    if (destinations[destination]) {
      const { radius: r, angle: a } = destinations[destination];
      const h = destinations[destination].height ?? defaultHeight;
      const th = destinations[destination].targetHeight ?? targetHeight;
      const deltaR = r - radius.current;
      const deltaA = a - angle.current;
      const deltaH = h - height.current;
      const deltaTH = th - target.current.y;
      // console.log(deltaR, deltaA);

      radius.current += f(deltaR) * 0.1; //delta * 3;
      angle.current += f(deltaA) * 0.1; //delta * 3;
      height.current += f(deltaH) * 0.09; //delta * 3;
      target.current.y += f(deltaTH) * 0.09; //delta * 3;
      if (
        Math.abs(deltaR) < MIN_DELTA &&
        Math.abs(deltaA) < MIN_DELTA &&
        Math.abs(deltaH) < MIN_DELTA
      ) {
        // dispatch(setCameraDestination(""));
        dispatch(setAnimationRunning(false));
        radius.current = r;
        angle.current = a;
        height.current = h;
        // setTimeout(() => goToHouseDetail(), 500);
      } else if (!animationRunning) {
        dispatch(setAnimationRunning());
      }
    }

    three.camera.position.y = height.current;
    three.camera.position.x = radius.current * Math.cos(angle.current) * ratio;
    three.camera.position.z = radius.current * Math.sin(angle.current);
    three.camera.lookAt(target.current);

    // use these logs to find desired camera positions
    // console.log({ radius: radius.current, angle: angle.current });
  });
  return null;
};
