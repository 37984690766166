import { FC } from "react";
import { IconCheck } from "./icons/check";
import c from "classnames";
import {
  DbEntry,
  selectModel,
  setCameraDestination,
  setDefaultView,
  setHoverCode,
  setScreen,
  setSectionView,
  setTier,
  setVariant,
} from "../slice";
import { useDispatch, useSelector } from "app/store";
import { useEventListener } from "app/event-listener";
import { useThree } from "@react-three/fiber";

interface Props {
  name: string;
  model?: DbEntry;
  code?: number;
  active?: boolean;
}

export const HouseScreenControlItem: FC<Props> = ({
  name,
  model,
  code,
  active: _active,
}) => {
  const dispatch = useDispatch();
  const selection = useSelector(s => s.ui.selection);
  const house = useSelector(s => s.ui.house);
  const hc = useSelector(s => s.ui.hoverCode);
  const chc = useSelector(s => s.ui.clickedHoverCode);
  const active = hc === code || _active;

  const onClick = () => {
    if (model) {
      dispatch(selectModel(model));
      dispatch(setScreen("house-detail"));
      if (selection.options[model] !== undefined) {
        dispatch(setVariant(selection.options[model][0]));
        dispatch(setTier(selection.options[model][1]));
      } else {
        dispatch(setVariant(0));
        dispatch(setTier(0));
      }
    }
  };
  // trigger the event if the canvas is clicked
  useEventListener("click", e => {
    if (
      (e.target as HTMLElement)?.tagName === "CANVAS" &&
      hc === code &&
      chc !== -1
    ) {
      // onClick();
    }
  });

  // see if the item is checked
  const checked = model ? selection.options[model] !== undefined : false;

  return (
    <div
      onClick={onClick}
      onPointerOver={e => {
        e.stopPropagation();
        // console.log("hover", _code);
        if (code) {
          dispatch(setHoverCode(code));
          dispatch(setCameraDestination(`${code} ${code} ${house}`));
          dispatch(setDefaultView(false));
          dispatch(setSectionView());
        }
      }}
      onPointerOut={() => {
        // console.log("hover out");
        dispatch(setHoverCode(-1));
      }}
      className={c(
        "cursor-pointer hover:opacity-75 flex items-center justify-start gap-6",
        {
          "text-white": !active,
          "text-black bg-white px-6 py-4": active,
        }
      )}
    >
      {checked ? (
        <IconCheck className="w-6 h-6" />
      ) : (
        <div
          className={c(
            "shrink-0 rounded-full border-[1.5px] w-6 h-6 opacity-50",
            { "border-white": !active, "border-[#242424]": active }
          )}
        ></div>
      )}
      <div className="uppercase text-1.5rem font-display">{name}</div>
    </div>
  );
};
