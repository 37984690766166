import {
  BBAnchor,
  Box,
  Html,
  MapControls,
  Sphere,
  useCursor,
  useTexture,
} from "@react-three/drei";
import { FC, useEffect, useState } from "react";
import { Grass } from "./grass";
import { useThree } from "@react-three/fiber";
import { PI, clamp } from "app/math";
import { HouseMapCard } from "features/ui/c/house-map-card";
import { selectHouse, setScreen } from "features/ui/slice";
import { TextItem } from "features/ui/c/text-item";
import { BackSide, Camera, Object3D, Vector3 } from "three";
import { CardSubheader } from "features/ui/c/card-subheader";
import { NumberItem } from "features/ui/c/number-item";
import { useDispatch, useSelector } from "app/store";
import {
  CONSTRUCTION_FILTER_ITEMS,
  PRICE_FILTER_ITEMS,
  ROOF_FILTER_ITEMS,
} from "config";
import { MapMarker } from "features/ui/c/map-marker";
import { MapScene } from "../meshes/map-scene.v4";
import { House1Map } from "../meshes/house1-map";
import { House2 } from "./house2";
import { CenterResizeScale } from "./center-resize-scale";
import { House2Map } from "../meshes/house2-map";
import { RoadMarks } from "../meshes/road-marks";
import { MapCameraControls } from "./map-camera-controls";
import { mouse } from "app/mouse";
import { Effects } from "./effects";

interface HouseProps {
  index: number;
}

const v = new Vector3();

const MapHouse: FC<HouseProps> = ({ index }) => {
  const [hover, setHover] = useState(false);
  useCursor(hover, "pointer", "auto");
  const dispatch = useDispatch();
  const props = useSelector(s => s.ui.houses[index]);
  const filterItems = useSelector(s => s.ui.filterItems);
  const numberFilterItems = useSelector(s => s.ui.numberedFilterItems);

  let disabled = false;
  // named filters
  if (
    !filterItems.construction
      .map(x => CONSTRUCTION_FILTER_ITEMS[x])
      .includes(props.construction)
  ) {
    disabled = true;
  }
  if (!filterItems.roof.map(x => ROOF_FILTER_ITEMS[x]).includes(props.roof)) {
    disabled = true;
  }
  if (
    !filterItems.price.map(x => PRICE_FILTER_ITEMS[x]).includes(props.price)
  ) {
    disabled = true;
  }
  // number filters
  if (numberFilterItems.energy[0] > props.energy) {
    disabled = true;
  }
  if (numberFilterItems.energy[1] < props.energy) {
    disabled = true;
  }
  if (numberFilterItems.constructionTime[0] > props.constructionTime) {
    disabled = true;
  }
  if (numberFilterItems.constructionTime[1] < props.constructionTime) {
    disabled = true;
  }
  if (numberFilterItems.lifetime[0] > props.lifetime) {
    disabled = true;
  }
  if (numberFilterItems.lifetime[1] < props.lifetime) {
    disabled = true;
  }

  const opacity = disabled ? 0.35 : hover ? 0.75 : 1;

  const positionScale = 5;

  return (
    <group
      onPointerOver={e => setHover(true)}
      onPointerOut={e => setHover(false)}
      position={[props.x * positionScale, 0, props.y * positionScale]}
      rotation={[0, props.rotation, 0]}
      onClick={e => {
        if (disabled) return;
        dispatch(selectHouse(index));
        dispatch(setScreen("house"));
      }}
    >
      {props.model === 1 && (
        <group position={[0.1, -0.26, -0.3]}>
          <House1Map />
        </group>
      )}
      {props.model === 2 && (
        <CenterResizeScale
          scale={[2.6, 2.6, 2.6]}
          position={[0, 0.8, 0.2]}
          rotation={[0, -PI / 2, 0]}
        >
          <House2Map />
        </CenterResizeScale>
      )}
      <BBAnchor anchor={[0, 2.5, 0]}>
        <Html center zIndexRange={[0, 0]}>
          <MapMarker
            disabled={disabled}
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            onClick={() => {
              if (disabled) return;
              dispatch(selectHouse(index));
              dispatch(setScreen("house"));
            }}
            fill={props.constructionTime + props.energy + props.lifetime}
          />
        </Html>
      </BBAnchor>
      {hover && (
        <BBAnchor anchor={[-1, 1, 0]}>
          <Html
            calculatePosition={(
              el: Object3D,
              camera: Camera,
              size: { width: number; height: number }
            ) => {
              // el.getWorldPosition(v);
              // const values = v; //.project(camera).addScalar(1).multiplyScalar(0.5);
              // console.log(values, values.toArray(), size);
              // values.x *= size.width;
              // values.y = size.height - values.y * size.height;
              // values.y = clamp(values.y, 650, size.height);
              const values = [
                mouse.x - 20,
                clamp(mouse.y, 650, size.height) - 20,
              ];
              return values;
            }}
          >
            <div className="w-[23rem] bg-white pointer-events-none transfrorm -translate-x-full -translate-y-full select-none animate-fade-in">
              <div
                onClick={() => dispatch(setScreen("house"))}
                className="flex cursor-pointer group justify-between items-center py-4 pl-6 pr-4 bg-dark text-white"
              >
                <h2 className="group-hover:opacity-75 font-display uppercase text-2xl leading-[normal]">
                  {props.name}
                </h2>
              </div>
              <img
                src={
                  props.model === 1 ? "./house-flat.jpg" : "./house-regular.jpg"
                }
                className="w-full h-40 object-cover"
              />
              <div className="pt-4 pb-8 px-6 bg-fancy flex flex-col gap-2">
                <TextItem title="Исполнение" text={props.construction} />
                <TextItem title="Крыша" text={props.roof} />
                <TextItem title="Класс стоимости" text={props.price} />

                <CardSubheader title="Показатели" />

                <NumberItem
                  title="Класс энергоэффективности"
                  number={props.energy}
                />
                <NumberItem
                  title="Срок возведения"
                  number={props.constructionTime}
                />
                <NumberItem title="Срок эксплуатации" number={props.lifetime} />
              </div>
            </div>
            {/* <HouseMapCard /> */}
          </Html>
        </BBAnchor>
      )}
    </group>
  );
};
export const FilterScene = () => {
  const { camera } = useThree();
  useEffect(() => {
    camera.position.set(0, 10, 10);
    camera.lookAt(0, 0, 0);
  }, []);
  const sky = useTexture("/sky.png");
  const houses = useSelector(state => state.ui.houses);
  return (
    <group>
      <Effects dof={false} />
      <Sphere args={[100, 64, 64]} position={[0, 0, 0]}>
        <meshBasicMaterial map={sky} color="#ffffff" side={BackSide} />
      </Sphere>
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 25]} intensity={1.0} />
      <MapScene />
      {/* <RoadMarks position={[200, 0.15, 0]} scale={5} /> */}
      {houses.map((_, i) => (
        <MapHouse key={i} index={i} />
      ))}
      {/* <MapControls /> */}
      <MapCameraControls />
      <group scale={[1.15, 1, 1.15]} position={[-23, 0, -33]}>
        {/* rip grass */}
        {/* <Grass fill useMapMask /> */}
      </group>
    </group>
  );
};
