import { useLoader } from "@react-three/fiber";
import { useSelector } from "app/store";
import { useEffect, useState } from "react";
import { FileLoader } from "three";

const AVAILABLE_SECTIONS = [
  "1-2-0-0.png",
  "1-2-0-1.png",
  "1-2-0-2.png",
  "1-2-1-0.png",
  "1-2-1-1.png",
  "1-2-1-2.png",
  "1-2-2-0.png",
  "1-2-2-1.png",
  "1-2-2-2.png",
  "1-2-3-0.png",
  "1-2-3-1.png",
  "1-2-3-2.png",
  "1-2-4-0.png",
  "1-2-4-1.png",
  "1-2-4-2.png",
  "1-4-0-0.png",
  "1-4-0-1.png",
  "1-4-0-2.png",
  "1-4-1-0.png",
  "1-4-1-1.png",
  "1-4-1-2.png",
  "1-4-2-0.png",
  "1-4-2-1.png",
  "1-4-2-2.png",
  "1-4-3-0.png",
  "1-4-3-1.png",
  "1-4-3-2.png",
  "1-5-0-0.png",
  "1-5-0-1.png",
  "1-6-0-0.png",
  "1-6-0-1.png",
  "1-6-0-2.png",
  "1-6-1-0.png",
  "1-6-1-1.png",
  "1-6-1-2.png",
  "1-6-2-0.png",
  "1-6-2-1.png",
  "1-6-2-2.png",
  "2-2-0-0.png",
  "2-2-0-1.png",
  "2-2-0-2.png",
  "2-2-1-0.png",
  "2-2-1-1.png",
  "2-2-1-2.png",
  "2-2-2-0.png",
  "2-2-2-1.png",
  "2-2-2-2.png",
  "2-2-3-0.png",
  "2-2-3-1.png",
  "2-2-3-2.png",
  "2-2-4-0.png",
  "2-2-4-1.png",
  "2-2-4-2.png",
  "2-4-0-0.png",
  "2-4-0-1.png",
  "2-4-0-2.png",
  "2-4-1-0.png",
  "2-4-1-1.png",
  "2-4-1-2.png",
  "2-4-2-0.png",
  "2-4-2-1.png",
  "2-4-2-2.png",
  "2-4-3-0.png",
  "2-4-3-1.png",
  "2-4-3-2.png",
  "2-5-0-0.png",
  "2-5-0-1.png",
  "2-6-0-0.png",
  "2-6-0-1.png",
  "2-6-0-2.png",
  "2-6-1-0.png",
  "2-6-1-1.png",
  "2-6-1-2.png",
  "2-6-2-0.png",
  "2-6-2-1.png",
  "2-6-2-2.png",
].map(x => `./section-pics/${x}`);

const MODELS: { [key: number]: string } = {
  "1": "крыша",
  "2": "межэтажное",
  "3": "стены",
  "4": "полы",
  "5": "цоколь",
  "6": "фундамент",
};

export const HouseSections = () => {
  const house = useSelector(s => s.ui.house);
  const hoverCode = useSelector(s => s.ui.hoverCode);
  const [model, setModel] = useState(0);
  useEffect(() => {
    if (hoverCode > 0) setModel(hoverCode);
  }, [hoverCode]);
  const [variant, tier] = useSelector(s => s.ui.selection.options)[
    MODELS[model]
  ] ?? [0, 0];
  const filename = `./section-pics/${house}-${model}-${variant}-${tier}.png`;
  const screen = useSelector(s => s.ui.screen);
  const show = useSelector(s => s.ui.sectionView);
  const animationRunning = useSelector(s => s.ui.animationRunning);
  if (screen === "filter") return null;
  if (screen === "configurator") return null;
  // don't render static images while the camera is moving
  if (animationRunning) return null;
  if (!AVAILABLE_SECTIONS.includes(filename)) return null;
  if (!show) return null;
  return (
    // <img className="w-full h-screen blur-sm object-contain" src={filename} />
    <img
      className="top-0 left-0 fixed w-full h-screen object-cover"
      src={filename}
    />
  );
};

// preload images to get rid of loading artifacts
for (let x of AVAILABLE_SECTIONS) {
  useLoader.preload(FileLoader, x);
}
